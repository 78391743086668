import { shallowEqual, useSelector } from 'react-redux';

import { GlobalState } from '@/store/';

const useShallowEqualSelector = <TState = GlobalState, TSelected = unknown>(
  selector: (state: TState) => TSelected
) => {
  return useSelector(selector, shallowEqual);
};
export default useShallowEqualSelector;

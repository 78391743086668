import React, { useEffect, useState, MouseEvent, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './style.scss';
import { useRouter } from 'next/router';
import FormRadio, { Option } from '@/components/atoms/formRadio';
import t from '@/lib/i18n';
import {
  PaymentStateType,
  StateType,
  CartItemType,
  SubCartType
} from '@/store/cart/types';
import CartSprite from '@/assets/images/sprites/cart.svg';
import PaymentMode from '@/assets/images/paymentMode.svg';
import CartHelp from '@/components/organisms/checkout/help';
import paymentApi from '@/lib/api/payment';
import Errors from '@/components/organisms/checkout/errors';
import { getPdvRef } from '@/lib/hooks/usePdv';
import UserInformationApi from '@/lib/api/userInformation';
import useEvent from '@/lib/hooks/useEvent';
import FidelityApi from '@/lib/api/fidelity';
import SideBarSummary from '@/components/organisms/checkout/sideBarSummary';
import VoucherApi from '@/lib/api/voucher';
import {
  UPDATE_SELECTED_FID,
  UPDATE_SELECTED_VOUCHER
} from '@/store/cart/cartActions';
import BottomFooter from '@/components/organisms/checkout/sideBarSummary/bottomFooter';
import { FidelityInfo } from '@/lib/api/fidelity/types';
import {
  getTransportDeliveryCost,
  handle409UpdateCart
} from '@/lib/utils/cart';
import Modal from '@/components/organisms/modal';
import { AuthStateType, AuthUserStateType } from '@/store/auth/authReducer';
import usePageView from '@/lib/hooks/usePageView';
import { DELIVERY_TYPE_MAPPING_INVERSE } from '@/lib/model/slot';
import DiscountCode from './discountCode';
import Fidelity from './fidelity';
import Voucher from './voucher';
import ModalPaymentProps from './modalPayment';
import CartModal from '../cart/modal';
import ClosedShelvesModal, {
  deleteAllClosedEans
} from '../slots/closedShelfModal';
import PaymentFallbackModal from './paymentFallbackModal';

export type PaymentProps = {
  cart: StateType;
  userId: string;
};

export type MethodsProps = {
  EN_LIGNE?: PaymentMethod | null;
  LIVRAISON?: PaymentMethod | null;
  RECEPTION?: PaymentMethod | null;
  BORNE?: PaymentMethod | null;
};

export type PaymentMethod = {
  id: string;
  label: string;
  value: string;
  content: JSX.Element;
};

export type ProductShelfClosed = {
  itemId: string;
};

export type SalesDepartments = {
  items: ProductShelfClosed[];
};

const OnlinePayment = () => {
  return (
    <div className="onlinePayment">
      <p className="payment__choice__text">{t('cart.payment.online.text')}</p>
      <div className="onlinePayment__cards">
        <img
          src={PaymentMode}
          className="onlinePayment__cards__picto"
          width={130}
          height={30}
          alt="online payment mode"
        />
        <p className="onlinePayment__cards__text">
          {t('cart.payment.online.desc')}
        </p>
      </div>
    </div>
  );
};

const ReceptionPayment = (means: Array<string>) => {
  if (means.includes('CHEQUE')) {
    return (
      <p
        className="payment__choice__text"
        dangerouslySetInnerHTML={{
          __html: t('cart.payment.RECEPTION.cheque.text')
        }}
      />
    );
  }
  return (
    <p
      className="payment__choice__text"
      dangerouslySetInnerHTML={{ __html: t('cart.payment.RECEPTION.text') }}
    />
  );
};

const mapProductShelfClosedCart = (
  cart: StateType,
  productShelfClosed: ProductShelfClosed[]
) => {
  const itmCArt: SubCartType | undefined = cart.subCarts.find(
    (subCart: SubCartType) => subCart.seller.sellerId === 'ITM'
  );

  return Object.keys(itmCArt?.items || {}).reduce(
    (acc: Array<CartItemType>, key: string) => {
      if (
        itmCArt?.items[key] &&
        productShelfClosed?.filter(
          (product) => product.itemId === itmCArt?.items[key].product.id
        ).length > 0
      ) {
        acc.push(itmCArt.items[key]);
      }
      return acc;
    },
    []
  );
};

const getProductsShelfClosedFromMetadata = (
  salesDepartmentsArray: SalesDepartments[]
) => {
  const productsShelfClosedArray = [] as ProductShelfClosed[];
  salesDepartmentsArray.forEach((dep) => {
    dep.items.forEach((item) => productsShelfClosedArray.push(item));
  });

  return productsShelfClosedArray;
};

const Payment = ({ cart, userId }: PaymentProps) => {
  const dispatch = useDispatch();
  const event = useEvent();
  const pageView = usePageView();
  const [paymentOptions, setPaymentOptions] = useState<Array<Option>>([]);
  const [paymentState, setPaymentState] = useState<PaymentStateType>({
    paymentType: null,
    paymentIsLoading: false,
    selectedInputFID: false,
    discountIsLoading: false,
    fidelityIsLoading: true,
    modalFidInfo: false,
    user: null,
    fidelity: null,
    voucher: {
      isLoading: true,
      data: null
    },
    modal: {
      isActive: false,
      title: null,
      text: null,
      button: null,
      picto: null,
      backToCart: false,
      isLoading: false,
      backTo: null
    },
    discountCode: null,
    payboxModalKo: false,
    modal409: {
      isActive: false,
      outOfStock: [],
      unavailblePromotion: [],
      unavailbleProducts: []
    },
    paymentFallback: { modal: false, warning: false }
  });
  const [buttonMobileDisabled, setButtonMobileDisabled] = useState(false);
  const [openClosedShelvesModal, setOpenClosedShelvesModal] = useState(false);
  const [productsShelfClosedTemp, setProductsShelfClosedTemp] = useState(
    [] as ProductShelfClosed[]
  );
  const [
    isDeleteProductsShelfClosedLoading,
    setIsDeleteProductsShelfClosedLoading
  ] = useState(false);

  // TODO : const userRedux = useShallowEqualSelector(selectUser);
  const userRedux = useSelector(
    ({ auth }: { auth: AuthStateType }) => auth.user
  );
  const pdvRef = getPdvRef();
  const immediateDiscount = String(cart.immediateDiscount.toFixed(2)).replace(
    '.',
    ','
  );

  useEffect(() => {
    (async () => {
      if (!userId) {
        return;
      }
      const user = await UserInformationApi.getInformations(userId);
      setPaymentState((p) => ({ ...p, user }));
    })();
  }, [userId]);

  const { current: cartRef } = useRef(cart);
  const router = useRouter();

  useEffect(() => {
    const pdvCart = cartRef.subCarts.find((sub) => sub.type === 'PDV');
    const products = Object.values(pdvCart?.items || {})?.map((item) => item);
    const deliveryPartner = cartRef.slots.list.delivery?.serviceProviders
      .filter(({ id }) => id === cartRef.slots.selectedProvider?.id)
      .map(({ desktopTitle }) => desktopTitle)
      .toString();
    !!products.length &&
      event.send('checkoutEcommerce', {
        step: '4',
        path: router.asPath,
        items: products,
        options: cartRef.slots.selectedSlot,
        deliveryPartner
      });
  }, [event, cartRef, router]);

  useEffect(() => {
    let fidelity: FidelityInfo | null;
    (async () => {
      if (!paymentState.user) {
        setPaymentState((p) => ({
          ...p,
          fidelityIsLoading: false
        }));
        return;
      }

      setPaymentState((p) => ({
        ...p,
        fidelityIsLoading: true,
        voucher: { ...p.voucher, isLoading: true }
      }));

      if (paymentState?.user?.fidelityCardNumber) {
        try {
          fidelity = await FidelityApi.getInfo({
            lastName: paymentState?.user?.lastName,
            firstName: paymentState?.user?.firstName,
            birthdayDate: paymentState?.user?.birthdayDate,
            fidelityCardNumber: paymentState?.user?.fidelityCardNumber
          });
        } catch (error: any) {
          try {
            fidelity = await FidelityApi.getInfo({
              lastName: paymentState?.user?.lastName,
              firstName: paymentState?.user?.firstName,
              birthdayDate: paymentState?.user?.birthdayDate,
              fidelityCardNumber: paymentState?.user?.fidelityCardNumber
            });
          } catch (err) {
            fidelity = null;
          }
        }
      }

      dispatch({
        type: UPDATE_SELECTED_VOUCHER,
        payload: 0
      });
      dispatch({
        type: UPDATE_SELECTED_FID,
        payload: 0
      });
      const vouchers = await VoucherApi.list(userId);
      const selectedVoucher = vouchers.find((v) => v.pdv.ref === pdvRef);

      setPaymentState((p) => ({
        ...p,
        fidelity,
        fidelityIsLoading: false,
        voucher: { isLoading: false, data: selectedVoucher || null }
      }));
    })();
  }, [paymentState.user, userId, pdvRef, dispatch]);

  useEffect(() => {
    const isThereItmCart = cart.subCarts?.some(
      (cartItem) => cartItem.type === 'PDV'
    );
    const availablePayments = isThereItmCart
      ? Object.keys(cart.slots.selectedSlot.availablePayments)
      : cart?.defaultPayments?.map(
          (defaultPayment) => defaultPayment?.paymentType
        );
    const computedPaymentOptions = availablePayments?.map((option, index) => {
      let content: JSX.Element;
      switch (option) {
        case 'LIVRAISON':
          content = (
            <p
              className="payment__choice__text"
              dangerouslySetInnerHTML={{
                __html: t('cart.payment.LIVRAISON.text')
              }}
            />
          );
          break;
        case 'EN_LIGNE':
          content = OnlinePayment();
          break;
        case 'RECEPTION':
          content = ReceptionPayment(
            cart.slots.selectedSlot.availablePayments[option]
          );
          break;
        case 'BORNE':
          content = (
            <p
              className="payment__choice__text"
              dangerouslySetInnerHTML={{
                __html: t('cart.payment.BORNE.text')
              }}
            />
          );
          break;

        default:
          content = OnlinePayment();
      }
      return {
        id: `${option}`,
        label: t(`cart.payment.${option}.title`),
        value: `${option}`,
        content
      };
    });

    const sortByOnlinePayment = (paymentsList: PaymentMethod[]) =>
      paymentsList.sort((a, b) => {
        const order = ['EN_LIGNE', 'LIVRAISON', 'RECEPTION', 'BORNE'];

        return order.indexOf(a.value) - order.indexOf(b.value);
      });
    const allPaymentMethods = sortByOnlinePayment(computedPaymentOptions || []);
    if (allPaymentMethods.length) {
      setPaymentState((p) => ({
        ...p,
        paymentType: allPaymentMethods[0].value
      }));
    }
    if (!allPaymentMethods.length) {
      window.location.href = '/orders/cart';
    }

    setPaymentOptions(allPaymentMethods);
  }, [
    cart?.defaultPayments,
    cart.slots.selectedSlot.availablePayments,
    cart.subCarts,
    cart.slots.selectedSlot.slot
  ]);

  const sendPayment = async (e?: MouseEvent<HTMLElement>) => {
    const pdvId = getPdvRef();
    if (e) {
      e.preventDefault();
    }
    try {
      setPaymentState((p) => ({ ...p, paymentIsLoading: true }));

      if (!userRedux) return;
      const userData: AuthUserStateType = {
        ...userRedux,
        lastName: userRedux?.lastName ?? paymentState.user?.lastName,
        firstName: userRedux?.firstName ?? paymentState.user?.firstName
      };
      const payment = await paymentApi.send(
        cart,
        pdvId,
        userData,
        paymentState,
        userId
      );

      if (payment.url) {
        window.location.href = payment.url;
      } else {
        event.send('api', { type: 'success', name: 'paymentStatus' });
        window.location.href = `/orders/confirmation?orderNumber=${payment.numeroCommande}`;
      }
    } catch (error: any) {
      event.send('api', { type: 'error', name: 'paymentStatus' });
      const displayCommonError = (code?: string) => {
        const { modalType, dataText, backToCart, dataTitle, backTo } = Errors(
          code || error?.response?.data?.errors?.[0]?.code,
          error?.response?.data?.errors?.[0]?.message
        );
        if (modalType === 'slotIssue') {
          pageView.send('SlotIssue');
        }
        const { deliveryType, availablePayments } = cart.slots.selectedSlot;

        const isPaymentFallbackPossible =
          modalType === 'retry' &&
          deliveryType === DELIVERY_TYPE_MAPPING_INVERSE.drive &&
          Object.keys(availablePayments).includes('RECEPTION');

        setPaymentState((p) => ({
          ...p,
          paymentIsLoading: false,
          ...(isPaymentFallbackPossible
            ? { paymentFallback: { modal: true, warning: true } }
            : {
                modal: {
                  isActive: true,
                  title: t(`cart.error.${modalType}.title`, {
                    '%data%': dataTitle
                  }),
                  text: t(`cart.error.${modalType}.text`, {
                    '%data%': dataText
                  }),
                  button: t(`cart.error.${modalType}.button`),
                  picto: 'warning',
                  isLoading: false,
                  backToCart,
                  backTo
                }
              })
        }));
      };
      if (error.response?.status === 409) {
        const { metaData } = error.response?.data || {};
        if (
          metaData?.unsynchronized?.clientItems?.length ||
          metaData?.unsynchronized?.serverItems?.length ||
          metaData?.unsynchronized?.clientPrice ||
          metaData?.unsynchronized?.serverPrice
        ) {
          setPaymentState((p) => ({
            ...p,
            paymentIsLoading: false,
            modal: {
              isActive: true,
              title: t(`cart.error.basket.title`),
              text: t(`cart.error.basket.text`),
              button: t(`cart.error.basket.button`),
              picto: 'warning',
              isLoading: false,
              backToCart: true,
              backTo: null
            }
          }));
        } else if (
          metaData?.invalid?.advantages?.length ||
          metaData?.invalid?.unavailables?.length ||
          metaData?.invalid?.stocks?.length ||
          metaData?.invalid?.partialStocks?.length
        ) {
          const getOrderItems409 = (
            type: 'advantages' | 'stocks' | 'unavailables' | 'partialStocks'
          ) => {
            const products: Array<CartItemType> = [];
            const { subCarts } = cart;
            subCarts.forEach((subCart) => {
              metaData?.invalid?.[type]?.forEach(
                (metaItem: { itemId: string; availableStock: number }) => {
                  const product = Object.values(subCart?.items || {})?.find(
                    (item) => item.product.id === metaItem.itemId
                  );
                  if (product) {
                    products.push({
                      ...product,
                      qty: 1,
                      sellerId: subCart.seller.sellerId
                    });
                  }
                }
              );
            });
            return products;
          };

          const unavailblePromotion = getOrderItems409('advantages') || [];
          const outOfStock = getOrderItems409('partialStocks') || [];
          const indispo = getOrderItems409('unavailables') || [];
          const stock = getOrderItems409('stocks') || [];
          const unavailbleProducts =
            indispo || stock ? [...indispo, ...stock] : null;
          setPaymentState((oldState) => ({
            ...oldState,
            paymentIsLoading: false,
            modal409: {
              ...oldState.modal409,
              isActive: true,
              unavailblePromotion,
              outOfStock,
              unavailbleProducts
            }
          }));
        } else if (
          metaData?.invalid?.services?.length ||
          metaData?.invalid?.deliveryMethods?.length
        ) {
          displayCommonError('COMMON_409');
        } else if (metaData?.invalid?.volume?.length) {
          setPaymentState((p) => ({
            ...p,
            paymentIsLoading: false,
            modal: {
              isActive: true,
              title: t(`cart.error.maxVolume.title`),
              text: t(`cart.error.maxVolume.text`, {
                '%data%': metaData?.invalid?.volume[0].cartVolume
              }),
              button: t(`cart.error.maxVolume.button`),
              picto: 'warning',
              isLoading: false,
              backToCart: true,
              backTo: null
            }
          }));
        } else if (metaData?.invalid?.salesDepartments?.length) {
          setPaymentState((p) => ({
            ...p,
            payboxModalKo: false,
            paymentIsLoading: false
          }));
          setOpenClosedShelvesModal(true);
          setProductsShelfClosedTemp(
            getProductsShelfClosedFromMetadata(
              metaData?.invalid?.salesDepartments
            )
          );
        }
      } else {
        displayCommonError();
      }
    }
  };

  const handleFallbackPayment = (paymentType: string) => {
    setPaymentState((oldState) => ({
      ...oldState,
      paymentType,
      paymentFallback: { ...oldState.paymentFallback, modal: false }
    }));
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const { availablePayments } = cart.slots.selectedSlot;
    const fallbackPaymentType = urlParams.get('fallback');
    const paymentType =
      fallbackPaymentType && availablePayments[fallbackPaymentType]
        ? fallbackPaymentType
        : paymentState.paymentType;

    setPaymentState((oldState) => ({
      ...oldState,
      payboxModalKo: urlParams.get('status') === 'KO',
      paymentFallback: {
        ...oldState.paymentFallback,
        warning: !!paymentType
      },
      paymentType
    }));
  }, [cart.slots.selectedSlot, paymentState.paymentType]);

  return (
    <div className="cart cart--checkout">
      <Modal
        className="commonErrorModal"
        confirmBtn={{
          label: t('modal.closedShelves.deleteProducts'),
          onClick: async () => {
            setIsDeleteProductsShelfClosedLoading(true);
            deleteAllClosedEans({
              productsShelfClosed: mapProductShelfClosedCart(
                cart,
                productsShelfClosedTemp
              ),
              dispatch,
              userId,
              updateSlot: false
            })
              .then(() => {
                router.push('/orders/cart');
              })
              .catch(() => {
                setIsDeleteProductsShelfClosedLoading(false);
              });
          },
          loading: isDeleteProductsShelfClosedLoading
        }}
        open={openClosedShelvesModal}
        onClose={() => {
          setOpenClosedShelvesModal(false);
        }}
      >
        <ClosedShelvesModal
          selectedSlot={cart.slots.selectedSlot.slot}
          productsShelfClosed={mapProductShelfClosedCart(
            cart,
            productsShelfClosedTemp
          )}
          isLoading={false}
        />
      </Modal>
      <Modal
        className="commonErrorModal"
        confirmBtn={{
          label: t('cart.payment.validation.paybox.KO.button'),
          onClick: () => {
            setPaymentState((oldState) => ({
              ...oldState,
              payboxModalKo: false
            }));
          }
        }}
        open={paymentState.payboxModalKo}
        onClose={() => {
          setPaymentState((oldState) => ({
            ...oldState,
            payboxModalKo: false
          }));
        }}
      >
        <svg width={128} className="commonErrorModal__picto">
          <use xlinkHref={`${CartSprite}#warning`} />
        </svg>
        <strong className="commonErrorModal__title">
          {t('cart.payment.validation.paybox.KO.title')}
        </strong>
        <p className="commonErrorModal__text">
          {t('cart.payment.validation.paybox.KO.text')}
        </p>
      </Modal>
      <PaymentFallbackModal
        open={paymentState.paymentFallback.modal}
        onClose={() => {
          setPaymentState((oldState) => ({
            ...oldState,
            paymentFallback: { ...oldState.paymentFallback, modal: false }
          }));
        }}
        handleFallbackPayment={handleFallbackPayment}
        availablePayments={cart.slots.selectedSlot.availablePayments}
      />

      <ModalPaymentProps
        paymentState={paymentState}
        setPaymentState={setPaymentState}
        sendPayment={sendPayment}
      />
      {cart.subCarts.length > 0 && (
        <>
          <div className="cart__content">
            <h1 className="cart__title">{t('cart.payment')}</h1>
            <div className="payment">
              <section className="payment__section payment__choice">
                <div className="payment__choice__intro">
                  <strong className="payment__choice__title">
                    {t('cart.payment.choice.title')}
                  </strong>
                  <p className="payment__choice__text">
                    {t('cart.payment.choice.text')}
                  </p>
                  {/*     {paymentState.paymentFallback.warning && (
                    <p className="payment__choice__warning">
                      <Warning className="payment__choice__warning__icon" />
                      {t('cart.payment.fallback.warning.text')}
                    </p>
                  )} */}
                </div>
                <div className="payment__choice__content">
                  <FormRadio
                    type="block"
                    itemsType="block"
                    name="payment"
                    value={paymentState?.paymentType || 'ENLIGNE'}
                    onFocusContent={true}
                    onChange={(value) => {
                      const paymentOpt =
                        paymentOptions.find((option) => option.value === value)
                          ?.id || '';
                      setPaymentState((p) => ({
                        ...p,
                        paymentType: paymentOpt
                      }));
                      event.send('checkout', {
                        type: 'paymentOption',
                        paymentOpt
                      });
                    }}
                    options={paymentOptions}
                  />
                </div>
              </section>
              {cart.subCarts?.some((cartItem) => cartItem.type === 'PDV') && (
                <>
                  <Fidelity
                    cart={cart}
                    paymentState={paymentState}
                    setPaymentState={setPaymentState}
                    discountCode={paymentState.discountCode}
                  />
                  <div className="payment__codes">
                    {cart.slots.discountCodeActive && (
                      <DiscountCode
                        cart={cart}
                        paymentState={paymentState}
                        setPaymentState={setPaymentState}
                        userId={userId}
                      />
                    )}
                    {cart.slots.vouchersCodeActive &&
                      paymentState.voucher.data && (
                        <Voucher
                          paymentState={paymentState}
                          cart={cart}
                          discountCode={paymentState.discountCode}
                        />
                      )}
                  </div>
                </>
              )}
              <CartHelp />
            </div>
          </div>
          <SideBarSummary
            isDisabled={(isDisabled) => {
              setButtonMobileDisabled(isDisabled);
            }}
            mobilePhone={paymentState.user?.mobilePhone}
            isPayment={true}
            href="/home"
            isLoading={paymentState.paymentIsLoading}
            onClick={async (e) => {
              e.preventDefault();
              if (!cart.selectedFID) {
                await sendPayment();
              } else {
                setPaymentState((state) => ({
                  ...state,
                  modalFidInfo: !state.modalFidInfo
                }));
              }
            }}
            disabled={!paymentState.paymentType}
            cart={cart}
            userId={userId}
            discountCode={paymentState.discountCode}
            totalDiscount={immediateDiscount}
          />
        </>
      )}
      <BottomFooter
        isPayment={true}
        cart={cart}
        isMobile={true}
        deliveryCost={getTransportDeliveryCost(cart)}
        totalDiscount={immediateDiscount}
        href="/home"
        isLoading={paymentState.paymentIsLoading}
        onClick={async (e) => {
          e.preventDefault();
          if (!cart.selectedFID) {
            await sendPayment();
          } else {
            setPaymentState((state) => ({
              ...state,
              modalFidInfo: !state.modalFidInfo
            }));
          }
        }}
        disabledButton={buttonMobileDisabled}
        discountCode={paymentState.discountCode}
      />
      <CartModal
        outOfStock={paymentState.modal409.outOfStock}
        unavailbleProducts={paymentState.modal409.unavailbleProducts}
        unavailblePromotion={paymentState.modal409.unavailblePromotion}
        hasAlcoholProduct={cart.hasAlcoholProduct}
        isLoading={false}
        open={paymentState.modal409.isActive}
        close={() => {
          setPaymentState((oldState) => ({
            ...oldState,
            modal409: {
              ...oldState.modal409,
              isActive: false
            }
          }));
        }}
        validate={async () => {
          setPaymentState((oldState) => ({
            ...oldState,
            modal409: {
              ...oldState.modal409,
              isActive: false
            }
          }));

          const productsToUpdate = paymentState.modal409.unavailbleProducts &&
            paymentState.modal409.outOfStock && [
              ...paymentState.modal409.unavailbleProducts,
              ...paymentState.modal409.outOfStock
            ];
          await handle409UpdateCart({
            productsToUpdate,
            dispatch
          });
          await sendPayment();
        }}
      />
    </div>
  );
};

export default Payment;

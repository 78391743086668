import React, { useState } from 'react';
import './style.scss';
import classNames from 'classnames';
import {
  RendererComponents as ContentfulRenderComponents,
  Component
} from '@/lib/contentful';
import t from '@/lib/i18n';
import Image from '@/components/atoms/image';
import CommonSprite from '@/assets/images/sprites/common.svg';
import AlcoholBanner from '@/components/molecules/alcoholBanner';
import LogoIntermarche from '@/assets/images/logo-app.png';
import Link from '@/components/atoms/link';

export type FooterPrimaryProps = {
  logos: Array<Component>;
  // eslint-disable-next-line react/no-unused-prop-types
  apps?: Array<{ [key: string]: string }>;
  nav: Array<Component>;
  pdvNav: Array<Component>;
  otherSites: Array<Component>;
  socialNetworks: Array<Component>;
};

const FooterPrimary = (props: FooterPrimaryProps) => {
  const [navOpen, setNavOpen] = useState(false);
  const [otherSitesOpen, setOtherSitesOpen] = useState(false);
  return (
    <>
      <div className="footerPrimary">
        <div className="footerPrimary__container">
          <div className="footerPrimary__logos">
            <ContentfulRenderComponents components={props.logos} />
          </div>
          <div className="footerPrimary__content">
            <div
              className={classNames({
                footerPrimary__links: true,
                'footerPrimary__links--open': navOpen
              })}
            >
              <div
                className="footerPrimary__links_title"
                onClick={() => setNavOpen(!navOpen)}
              >
                <h2>{t('footer.direct_access')}</h2>
                <svg className="fill--white">
                  <use xlinkHref={`${CommonSprite}#arrow-up`} />
                </svg>
              </div>
              <div className="footerPrimary__links_content">
                <ContentfulRenderComponents components={props.nav} />
              </div>
            </div>
            <div
              className={classNames({
                footerPrimary__links: true,
                'footerPrimary__links--open': otherSitesOpen
              })}
            >
              <div
                className="footerPrimary__links_title"
                onClick={() => setOtherSitesOpen(!otherSitesOpen)}
              >
                <h2>{t('footer.sites')}</h2>
                <svg className="fill--white">
                  <use xlinkHref={`${CommonSprite}#arrow-up`} />
                </svg>
              </div>
              <div className="footerPrimary__links_content">
                <ContentfulRenderComponents components={props.otherSites} />
              </div>
            </div>
            <div className="footerPrimary__apps">
              <h2>{t('footer.apps')}</h2>
              {props?.apps?.map((app, index) => {
                const key = `app-${index}`;
                return (
                  <div key={key} className="footerPrimary__app">
                    <Link href={app.href} target="_blank" title="Intermarché">
                      <Image src={app.image} alt="" width={65} height={65} />
                      <Image
                        src={LogoIntermarche}
                        alt="Intermarché"
                        width={65}
                        height={65}
                      />
                    </Link>
                    <div className="footerPrimary__app_content">
                      <div className="footerPrimary__app_title">
                        {app.title}
                      </div>
                      <div className="footerPrimary__app_title">
                        {app.subTitle}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <AlcoholBanner />
    </>
  );
};

export default FooterPrimary;

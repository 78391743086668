import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import t from '@/lib/i18n';
import Date, { getHoursColor } from '@/lib/utils/date';
import useClickOut from '@/lib/hooks/useClickOut';
import { PdvProps } from '@/lib/model/pdv';
import CommonSprite from '@/assets/images/sprites/common.svg';
import Link from '@/components/atoms/link';
import Modal from '@/components/organisms/modal';
import StoreLocator from '@/components/organisms/storeLocator';
import classNames from 'classnames';
import useEvent from '@/lib/hooks/useEvent';
import ChooseStore from '@/components/organisms/chooseStore';
import { SET_PDV } from '@/store/common/commonActions';

export type ContentProps = {
  pdv?: PdvProps;
};

export type StoreLayerProps = {
  pdv?: PdvProps;
  close: () => void;
};

const LayerStore = ({ pdv, close }: StoreLayerProps) => {
  const [clickOutRef, clickOutHandler] = useClickOut();
  const event = useEvent();

  clickOutHandler(() => {
    close();
  });

  if (!pdv) {
    return null;
  }

  let openingMessage = '';

  if (pdv.isOpen && pdv.nextClosingDate) {
    const nextClosingDate = Date(pdv.nextClosingDate);

    openingMessage = t('header.storeInfo.openingMessage.todayUntil', {
      '%hours%': nextClosingDate.format('HH'),
      '%minutes%': nextClosingDate.format('mm')
    });
  } else if (!pdv.isOpen && pdv.nextOpeningDate) {
    const nextOpeningDate = Date(pdv.nextOpeningDate);
    const today = Date().hour(0).minute(0);
    const tomorrow = today.add(1, 'day');

    if (nextOpeningDate > today && nextOpeningDate < tomorrow) {
      openingMessage = t('header.storeInfo.openingMessage.today', {
        '%hours%': nextOpeningDate.format('HH'),
        '%minutes%': nextOpeningDate.format('mm')
      });
    } else if (
      nextOpeningDate >= tomorrow &&
      nextOpeningDate < tomorrow.add(1, 'day')
    ) {
      openingMessage = t('header.storeInfo.openingMessage.tomorrow', {
        '%hours%': nextOpeningDate.format('HH'),
        '%minutes%': nextOpeningDate.format('mm')
      });
    } else {
      openingMessage = t('header.storeInfo.openingMessage', {
        '%date%': nextOpeningDate.format('dddd'),
        '%hours%': nextOpeningDate.format('HH'),
        '%minutes%': nextOpeningDate.format('mm')
      });
    }
  }

  return (
    <div id="pdv-layer" ref={clickOutRef} className="store__layer">
      <div className="store__layer__content">
        <ul>
          <li className="store__item">
            <svg className="store__item__picto" height="24" width="24">
              <use xlinkHref={`${CommonSprite}#store`} />
            </svg>
            <div className="store__info">
              <span className="store__layerName">
                {t('header.storeInfo.title', {
                  '%format%': pdv.format.toUpperCase(),
                  '%city%': pdv.address.city
                })}
              </span>
              {!pdv.isOpen && (
                <>
                  <span className="store__highlight store__highlight--closed">
                    {t('header.storeInfo.closed')}
                  </span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: openingMessage
                    }}
                  />
                </>
              )}
              {pdv.isOpen && openingMessage && (
                <div
                  className={classNames(
                    'store__highlight',
                    `store__highlight--${getHoursColor(
                      Date(pdv.nextClosingDate)
                    )}`
                  )}
                >
                  {openingMessage}
                </div>
              )}
              {pdv.exceptionDate?.type === 'opening' && (
                <div
                  className="store__exceptionDate__open"
                  dangerouslySetInnerHTML={{
                    __html: t('pdv.exceptionOpening.control', {
                      '%date%': Date(pdv.exceptionDate.startDate).format(
                        'dddd DD MMMM'
                      ),
                      '%beginH%': pdv.exceptionDate?.begin?.hour,
                      '%beginM%': pdv.exceptionDate?.begin?.minute,
                      '%endH%': pdv.exceptionDate?.end?.hour,
                      '%endM%': pdv.exceptionDate?.end?.minute
                    })
                  }}
                />
              )}
              {pdv.exceptionDate?.type === 'closure' && (
                <div
                  className="store__exceptionDate__closed"
                  dangerouslySetInnerHTML={{
                    __html: t('pdv.exceptionClosure.control', {
                      '%date%': Date(pdv.exceptionDate.startDate).format(
                        'dddd DD MMMM'
                      )
                    })
                  }}
                />
              )}
            </div>
          </li>
          <li className="store__item">
            <svg className="store__item__picto" height="24" width="24">
              <use xlinkHref={`${CommonSprite}#localisation`} />
            </svg>
            <address className="store__info">
              {pdv.address.address1}
              <br />
              {pdv.address.address2 && (
                <>
                  {pdv.address.address2}
                  <br />
                </>
              )}
              {pdv.address.zipCode} {pdv.address.city}
            </address>
          </li>
          <li className="store__item">
            <svg className="store__item__picto" height="24" width="24">
              <use xlinkHref={`${CommonSprite}#tel`} />
            </svg>
            <a href={`tel:${pdv.phone}`} className="store__info">
              {pdv.phone}
            </a>
          </li>
          <li className="store__item">
            <div className="store__info__legalNotice">
              {t('common.phoneLegalNotice')}
            </div>
          </li>
        </ul>
        <div className="store__actions">
          <Link
            label={t('header.storeInfo.storeDetail.ctaLabel')}
            onClick={() =>
              event.send('pdv', {
                type: 'storeDetail',
                pdvInfo: `${pdv.ref}::${pdv.address.city}`
              })
            }
            button={true}
            href="/store"
          />
          <ChooseStore
            type="link"
            className="store__link"
            title={t('header.storeInfo.chooseStore.title')}
            content={t('header.storeInfo.chooseStore.content')}
            ctaLabel={t('header.storeInfo.chooseStore.ctaLabel')}
            storeLocator={{
              withInStore: true,
              withDrive: true,
              withDelivery: true
            }}
          />
        </div>
      </div>
    </div>
  );
};

const Content = ({ pdv }: ContentProps) => {
  const [layerIsOpen, setLayerIsOpen] = useState(false);
  const [isStoreLocatorOpened, setStoreLocatorOpened] = useState(false);
  const event = useEvent();
  const dispatch = useDispatch();

  useEffect(() => {
    if (pdv) {
      dispatch({
        type: SET_PDV,
        payload: {
          pdv
        }
      });
    }
  }, [pdv, dispatch]);

  useEffect(() => {
    if (pdv?.isClosed) {
      setStoreLocatorOpened(true);
    }
  }, [pdv?.isClosed]);

  if (!pdv) {
    return null;
  }

  return (
    <>
      {pdv.address && (
        <div
          id="headerStoreName"
          className="store__name"
          onClick={() => {
            event.send('pdv', {
              type: 'openLayer',
              pdvInfo: `${pdv.ref}::${pdv.address?.city}`
            });
            setLayerIsOpen((open) => !open);
          }}
        >
          {pdv.address.city}
          <svg className="fill--black" height="10" width="10">
            <use xlinkHref={`${CommonSprite}#arrow-up`} />
          </svg>
        </div>
      )}
      {layerIsOpen && (
        <LayerStore
          pdv={pdv}
          close={() => {
            setLayerIsOpen((open) => !open);
          }}
        />
      )}
      <Modal
        className="chooseStore__modal"
        open={isStoreLocatorOpened}
        scrollable={false}
        onClose={() => {
          setStoreLocatorOpened(false);
        }}
        closable={false}
        pageView="storeLocator"
      >
        <h1 className="header__title">
          {t('header.storeInfo.pdvClosed.title', {
            '%city%': pdv.address?.city ?? ''
          })}
        </h1>
        <p>{t('header.storeInfo.pdvClosed.content')}</p>
        <StoreLocator withDelivery={true} withDrive={true} withInStore={true} />
      </Modal>
    </>
  );
};

export default Content;

import React, { useCallback } from 'react';
import t from '@/lib/i18n';
import logger from '@/lib/logger/base';
import { Controller } from 'react-hook-form';
import { DQEAddress } from '@/lib/api/address/types';
import citiesApi from '@/lib/api/address/cities';
import FormSelect from '@/components/atoms/formSelect/selectV2';

const AutocompleteStreet = ({
  errors,
  control,
  trigger,
  setValue,
  setAutocompleteStreetInput,
  clearAddress
}: any) => {
  const loadOptions = useCallback(async (inputValue) => {
    if (!inputValue || inputValue.trim() === '') {
      return [];
    }

    try {
      const defaultOption: any = [
        { value: '-1', label: `${t('address.label.notFound')}` }
      ];
      const dataAddresses = await citiesApi.search(inputValue);
      const data = dataAddresses
        .map(
          ({ streetName, checksum, postalCode, city, country }: DQEAddress) => {
            return {
              label: streetName,
              value: checksum,
              postalCode,
              city,
              country
            };
          }
        )
        .concat(defaultOption);
      return data;
    } catch (error) {
      logger.error(error);
      return [];
    }
  }, []);

  const formatOptionLabel = ({ label, postalCode, city }: any) => {
    return (
      <div style={{ flex: '1' }}>
        <p style={{ padding: 0, margin: 0, fontSize: '13px' }}>
          <strong>
            {label} {postalCode} {city}
          </strong>
        </p>
      </div>
    );
  };

  return (
    <Controller
      control={control}
      name="address"
      rules={{
        required: true,
        validate: {
          isValidString: (value) => {
            return value?.trim() !== '';
          }
        }
      }}
      render={({ onChange, value, name, ref }) => (
        <FormSelect
          id={name}
          name={name}
          value={value?.label}
          isSearchable
          isClearable
          ref={ref}
          label={`${t('address.label.address')} *`}
          placeholder=""
          className="inputRegister"
          formatOptionLabel={formatOptionLabel}
          loadOptions={loadOptions}
          onChange={(selectedValue: any) => {
            if (selectedValue?.value === '-1') {
              setAutocompleteStreetInput(false);
              clearAddress();
              return;
            }
            onChange(selectedValue?.label);
            setValue('city', selectedValue?.city);
            setValue('zipCode', selectedValue?.postalCode);
            setValue('country', selectedValue?.country);
            trigger(['address']);
          }}
          errorMessage={
            errors.address ? t(`form.error.${errors.address.type}`) : ''
          }
        />
      )}
    />
  );
};

export default AutocompleteStreet;

import React, { useEffect, useState } from 'react';
import { Dayjs } from 'dayjs';
import { Controller, useForm, FieldError } from 'react-hook-form';
import FormRadio from '@/components/atoms/formRadio';
import t from '@/lib/i18n';
import {
  USER_INFORMATIONS,
  USER_FIDELITY
} from '@/store/register/registerActions';
import Button from '@/components/atoms/button';
import FormInput from '@/components/atoms/formInput';
import FormDate from '@/components/atoms/formDate';
import FormFidelityCard from '@/components/atoms/formFidelityCard';
import Date from '@/lib/utils/date';
import FidelityApi from '@/lib/api/fidelity/public';
import { isValidNIF } from '@/lib/utils/company';
import { NewUserLocal } from '@/store/register/types';
import { useRouter } from 'next/router';
import {
  CardSizeErrorException,
  ICardValidationErrorException
} from '@/lib/api/fidelity/exceptions';
import {
  Content as ContentfulContent,
  RendererComponents as ContentfulRenderComponents
} from '@/lib/contentful';
import Modal from '@/components/organisms/modal';
import { FidelityInfo } from '@/lib/api/fidelity/types';
import Title from '@/components/atoms/title';
import getConfigValue from '@/lib/config';

const IS_FIDELITY_ENABLED = getConfigValue(
  'IS_FIDELITY_ENABLED',
  false
).toBoolean();
const PRO_NIF_ALLOWED_FIRST_DIGIT = ['5', '6', '8', '9'];
const INDIVIDUAL_NIF_ALLOWED_FIRST_DIGIT = ['1', '2', '3', '4'];

type InfosProps = {
  isPro: number;
  company?: string;
  nif?: string;
  hasFidelityCard: number;
  fidelityCardNumber?: string;
  civility: number;
  firstName: string;
  lastName: string;
  birthdayDate: Dayjs;
};

export type StepProps = {
  user: NewUserLocal;
  dispatch: any;
  cgu: ContentfulContent;
};

type StateTypeProps = {
  cardValidityErrorMessage: ICardValidationErrorException | undefined;
  isLoading: boolean;
  isProContext: boolean;
  isFidelityContext: boolean;
  modalIsOpen: boolean;
  cartInfos: FidelityInfo | null;
  cguNotAccepted: boolean;
};

const Informations = ({ user, dispatch, cgu }: StepProps) => {
  const [state, setState] = useState<StateTypeProps>({
    cardValidityErrorMessage: undefined,
    isLoading: false,
    isProContext: false,
    isFidelityContext: false,
    modalIsOpen: false,
    cartInfos: null,
    cguNotAccepted: false
  });

  const router = useRouter();

  const {
    control,
    errors,
    register,
    handleSubmit,
    getValues,
    trigger
  } = useForm<InfosProps>({
    defaultValues: {
      isPro: user.isPro || 0,
      company: user?.company || undefined,
      nif: user?.nif || undefined,
      hasFidelityCard: IS_FIDELITY_ENABLED ? user.hasFidelityCard : 0,
      fidelityCardNumber: user.fidelityCardNumber || undefined,
      civility: user.civility,
      firstName: user.firstName || '',
      lastName: user.lastName || '',
      birthdayDate: user.birthdayDate
    }
  });

  const dispatchCguChoice = async (cguFid: boolean) => {
    setState((prevState) => ({
      ...prevState,
      modalIsOpen: false,
      isLoading: false,
      cguNotAccepted: !cguFid
    }));

    if (!cguFid) {
      return;
    }

    dispatch({
      type: USER_FIDELITY,
      payload: { cguValided: cguFid, cardInfos: state.cartInfos }
    });

    dispatchForm();
  };

  const dispatchForm = async () => {
    const form = getValues();

    await dispatch({
      type: USER_INFORMATIONS,
      payload: {
        isPro: Number(form.isPro),
        company: form.company && form.company,
        nif: form.nif && form.nif,
        hasFidelityCard: Number(form.hasFidelityCard),
        fidelityCardNumber: form.fidelityCardNumber,
        civility: Number(form.civility),
        firstName: form.firstName.trim(),
        lastName: form.lastName.trim(),
        birthdayDate: form.birthdayDate
      }
    });
    router.push('/my-account/create-my-account/address');
  };

  const onSubmit = async (form: InfosProps) => {
    setState((s) => ({
      ...s,
      cguNotAccepted: false,
      isLoading: true
    }));

    if (Number(form.hasFidelityCard) === 1) {
      if (form.fidelityCardNumber?.length !== 19) {
        setState((s) => ({
          ...s,
          isLoading: false,
          cardValidityErrorMessage: new CardSizeErrorException()
        }));
        return;
      }
      const body = {
        firstName: form.firstName.trim(),
        lastName: form.lastName.trim(),
        fidelityCardNumber: form.fidelityCardNumber,
        birthdayDate: form.birthdayDate
      };
      let currentError: ICardValidationErrorException;
      let cartInfos: FidelityInfo | null;
      try {
        cartInfos = await FidelityApi.isValidFidelity(body);
      } catch (error: any) {
        currentError = error;
      } finally {
        setState((s) => ({
          ...s,
          isLoading: false,
          cartInfos,
          modalIsOpen: !currentError,
          cardValidityErrorMessage: currentError
        }));
      }
    } else {
      dispatchForm();
    }
  };
  return (
    <form className="formSubscribe" onSubmit={handleSubmit(onSubmit)}>
      <div className="title">{t('createAccount.form.personalInfo')}</div>
      <div className="box">
        <div className="card">
          <Controller
            control={control}
            name="isPro"
            render={({ onChange, value, name }) => {
              useEffect(() => {
                value === 1
                  ? setState((s) => ({
                      ...s,
                      isProContext: true
                    }))
                  : setState((s) => ({
                      ...s,
                      isProContext: false
                    }));
              }, [value]);
              return (
                <FormRadio
                  className="inputRegister"
                  name={name}
                  type="block"
                  label={`${t('myAccount.myDetails.label.isPro')} *`}
                  options={[
                    {
                      id: 'no',
                      label: t('myAccount.myDetails.label.not-pro'),
                      value: 0
                    },
                    {
                      id: 'yes',
                      label: t('myAccount.myDetails.label.pro'),
                      value: 1
                    }
                  ]}
                  value={Number(value)}
                  onChange={(newValue) => onChange(newValue)}
                />
              );
            }}
          />
          {state.isProContext ? (
            <>
              <Controller
                control={control}
                name="company"
                defaultValue={undefined}
                rules={{
                  required: true,
                  maxLength: 32,
                  validate: {
                    isValidString: (value) => {
                      return value.trim() !== '';
                    }
                  }
                }}
                render={({ onChange, value, name }) => (
                  <FormInput
                    id="company"
                    name="company"
                    className="inputRegister"
                    ref={register({ required: true, maxLength: 32 })}
                    onChange={(newValue) => {
                      onChange(newValue);
                      trigger(name);
                    }}
                    value={value}
                    type="text"
                    label={`${t('register.company.label')} *`}
                    message={t('form.error.maxLength', {
                      '%max%': 32
                    })}
                    maxLength={32}
                    errorMessage={
                      errors.company
                        ? t(`form.error.${errors.company.type}`)
                        : ''
                    }
                  />
                )}
              />
            </>
          ) : (
            IS_FIDELITY_ENABLED && (
              <Controller
                className="inputRegister"
                control={control}
                rules={{ required: true, valueAsNumber: true }}
                name="hasFidelityCard"
                render={({ onChange, value, name, ref }) => {
                  useEffect(() => {
                    Number(value)
                      ? setState((s) => ({
                          ...s,
                          isFidelityContext: true
                        }))
                      : setState((s) => ({
                          ...s,
                          isFidelityContext: false
                        }));
                  }, [value]);
                  return (
                    <div className="inputRegister">
                      <FormRadio
                        name={name}
                        id={name}
                        ref={ref}
                        label={t('createAccount.form.fidelidyCardMesage')}
                        errorMessage={
                          errors.hasFidelityCard
                            ? t('register.hasFidelityCard.error')
                            : ''
                        }
                        options={[
                          {
                            id: 'yes',
                            label: t('createAccount.form.yes'),
                            value: 1
                          },
                          {
                            id: 'no',
                            label: t('createAccount.form.no'),
                            value: 0
                          }
                        ]}
                        onChange={(newValue) => {
                          onChange(newValue);
                        }}
                        value={IS_FIDELITY_ENABLED ? Number(value) : 0}
                      />

                      {value === 0 && (
                        <div className="noCard">
                          {t('register.noFidelityCard.message')}
                        </div>
                      )}
                    </div>
                  );
                }}
              />
            )
          )}
          {IS_FIDELITY_ENABLED &&
            state.isFidelityContext &&
            !state.isProContext && (
              <Controller
                control={control}
                name="fidelityCardNumber"
                rules={{
                  required: true
                }}
                render={({ onChange, value, name }) => (
                  <>
                    <FormFidelityCard
                      className="inputRegister"
                      placeholder="3250 / 390 / XXXX / XXXX / XXXX"
                      id={name}
                      name={name}
                      value={value}
                      onChange={(newValue) => onChange(newValue)}
                      label={t('register.fidelity.label')}
                      errorMessage={
                        errors.fidelityCardNumber
                          ? t(
                              `form.error.fidelityCardNumber.${errors.fidelityCardNumber.type}`
                            )
                          : ''
                      }
                    />
                    {state.cardValidityErrorMessage ? (
                      <div className="inputRegister__error">
                        {state.cardValidityErrorMessage.getTrad()}
                      </div>
                    ) : (
                      <div className="inputRegister__message">
                        {t('register.fidelity.message')}
                      </div>
                    )}
                  </>
                )}
              />
            )}

          <Controller
            control={control}
            name="civility"
            rules={{ required: true }}
            render={({ onChange, value, name, ref }) => (
              <FormRadio
                className="inputRegister"
                ref={ref}
                type="block"
                id={name}
                name={name}
                label={`${t('myAccount.myDetails.label.civility')} *`}
                options={[
                  {
                    id: 'female',
                    label: t('myAccount.myDetails.label.female'),
                    value: 2
                  },
                  {
                    id: 'male',
                    label: t('myAccount.myDetails.label.male'),
                    value: 1
                  }
                ]}
                value={Number(value)}
                onChange={(newValue) => onChange(newValue)}
                errorMessage={
                  errors.civility
                    ? t(`form.error.${(errors.civility as FieldError).type}`)
                    : ''
                }
              />
            )}
          />

          <FormInput
            id="nif"
            name="nif"
            type="text"
            label={`${t('register.nif.label')} ${
              state.isProContext ? '*' : ''
            }`}
            className="inputRegister"
            maxLength={9}
            ref={register({
              required: false,
              minLength: 0,
              validate: {
                isValidNIF: (nif) => {
                  if (
                    nif &&
                    state.isProContext &&
                    !PRO_NIF_ALLOWED_FIRST_DIGIT.includes(nif[0])
                  ) {
                    return false;
                  }
                  if (
                    nif &&
                    !state.isProContext &&
                    !INDIVIDUAL_NIF_ALLOWED_FIRST_DIGIT.includes(nif[0])
                  ) {
                    return false;
                  }
                  if (!nif && !state.isProContext) {
                    return true;
                  }
                  return isValidNIF(nif);
                }
              }
            })}
            errorMessage={errors.nif ? t('register.nif.error') : ''}
          />

          <Controller
            control={control}
            name="firstName"
            defaultValue={undefined}
            rules={{
              required: true,
              maxLength: 16,
              validate: {
                isValidString: (value) => {
                  return value.trim() !== '';
                }
              }
            }}
            render={({ onChange, value, name }) => (
              <FormInput
                id="firstName"
                name="firstName"
                className="inputRegister"
                ref={register({ required: true, maxLength: 16 })}
                label={`${t('myAccount.myDetails.label.firstName')} *`}
                message={t('form.error.maxLength', {
                  '%max%': 16
                })}
                value={value}
                onChange={(newValue) => {
                  onChange(newValue);
                  trigger(name);
                }}
                maxLength={16}
                errorMessage={
                  errors.firstName
                    ? t(`form.error.${errors.firstName.type}`)
                    : ''
                }
              />
            )}
          />

          <Controller
            control={control}
            name="lastName"
            defaultValue={undefined}
            rules={{
              required: true,
              maxLength: 32,
              validate: {
                isValidString: (value) => {
                  return value.trim() !== '';
                }
              }
            }}
            render={({ onChange, value, name }) => (
              <FormInput
                id="lastName"
                name="lastName"
                ref={register({ required: true, maxLength: 32 })}
                type="text"
                className="inputRegister"
                value={value}
                onChange={(newValue) => {
                  onChange(newValue);
                  trigger(name);
                }}
                label={`${t('myAccount.myDetails.label.lastName')} *`}
                message={t('form.error.maxLength', {
                  '%max%': 32
                })}
                maxLength={32}
                errorMessage={
                  errors.lastName ? t(`form.error.${errors.lastName.type}`) : ''
                }
              />
            )}
          />

          {!state.isProContext && (
            <Controller
              control={control}
              name="birthdayDate"
              defaultValue={undefined}
              rules={{
                required: true,
                validate: {
                  isValidDate: (value) => {
                    return !!value && Date(value).isValid();
                  },
                  isInAge: (value) => {
                    return Date().diff(value, 'year') >= 18;
                  }
                }
              }}
              render={({ onChange, value, name }) => (
                <FormDate
                  className="inputRegister"
                  id={name}
                  value={value}
                  placeholder={t('register.birthday.placeholder')}
                  label={`${t('myAccount.myDetails.label.birthdayDate')} *`}
                  onChange={(newValue) => {
                    onChange(newValue);
                    trigger(name);
                  }}
                  message={t('register.birthday.message')}
                  errorMessage={
                    errors.birthdayDate
                      ? t(
                          `form.error.${
                            (errors.birthdayDate as FieldError).type
                          }`
                        )
                      : ''
                  }
                />
              )}
            />
          )}
        </div>
      </div>
      <Modal
        className="cguModal"
        pageView="CGUFidelityCreation"
        open={state.modalIsOpen}
        scrollable={true}
        closable={false}
        confirmBtn={{
          label: t('register.cgu.btnConfirm'),
          onClick: () => dispatchCguChoice(true),
          loading: state.isLoading,
          id: 'acceptCgu'
        }}
        cancelBtn={{
          disabled: state.isLoading,
          label: t('register.cgu.btnCancel'),
          onClick: () => dispatchCguChoice(false),
          id: 'declineCgu'
        }}
        onClose={() =>
          setState((s) => ({
            ...s,
            modalIsOpen: false
          }))
        }
      >
        <div className="cguWrapper">
          <Title tag="h1" label={t('common.cguFid.popinTitle')} />
          <ContentfulRenderComponents components={cgu.components} />
        </div>
      </Modal>

      {state.cguNotAccepted && (
        <div className="inputRegister__error">{t('register.cgu.error')}</div>
      )}

      <div className="footer">
        <div className="footer__required">
          {t('register.footer.requiredFields')}
        </div>
        <div className="footer__btn">
          <Button
            id="signUpFormInformationsBack"
            color="secondary"
            onClick={() =>
              router.push('/my-account/create-my-account', undefined, {
                shallow: true
              })
            }
            disabled={state.isLoading}
            label={t('common.back')}
          />
          <Button
            id="signUpFormInformationsNext"
            type="submit"
            onClick={() => {
              trigger(['firstName', 'lastName', 'birthdayDate']);
            }}
            label={t('common.next')}
            loading={state.isLoading}
          />
        </div>
      </div>
    </form>
  );
};

export default Informations;

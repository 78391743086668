import React from 'react';
import './style.scss';
import t from '@/lib/i18n';
import classname from 'classnames';
import MyAccountSprite from '@/assets/images/sprites/myAccount.svg';
import { Order } from '@/lib/api/order/types';

type OrderStepperProps = {
  className?: string;
  order: Order;
};

const OrderStepper = ({ className, order }: OrderStepperProps) => {
  return (
    <div className={classname('orderStepper', className)}>
      <ul className="orderStepper__steps">
        <li
          className={classname('orderStepper__step', {
            'orderStepper__step--active': order.status === 'validated',
            'orderStepper__step--passed': [
              'preparing',
              'prepared',
              'delivering',
              'delivered',
              'withdrawal'
            ].includes(order.status)
          })}
        >
          <div className="orderStepper__picto">
            <svg height="20" width="20">
              <use xlinkHref={`${MyAccountSprite}#cart`} />
            </svg>
          </div>
          <div
            className={classname('orderStepper__label', {
              'orderStepper__label--active': order.status === 'canceled'
            })}
          >
            {t(
              `orderStepper.status.${
                order.status === 'canceled' ? 'canceled' : 'created'
              }At`,
              {
                '%date%':
                  order.status === 'canceled' && order.canceledDate
                    ? order.canceledDate.format('DD/MM/YYYY')
                    : order.createdAt.format('DD/MM/YYYY')
              }
            )}
          </div>
        </li>
        <li
          className={classname('orderStepper__step', {
            'orderStepper__step--active': order.status === 'preparing',
            'orderStepper__step--passed': [
              'prepared',
              'delivering',
              'delivered',
              'withdrawal'
            ].includes(order.status)
          })}
        >
          <div className="orderStepper__picto">
            <svg height="25" width="25">
              <use xlinkHref={`${MyAccountSprite}#preparing`} />
            </svg>
          </div>
          <div className="orderStepper__label">
            {t('orderStepper.status.preparing')}
            {order.status === 'preparing' && (
              <div className="orderStepper__label__preparing">
                {t('orderStepper.message.preparing')}
              </div>
            )}
          </div>
        </li>
        <li
          className={classname('orderStepper__step', {
            'orderStepper__step--active': ['prepared'].includes(order.status),
            'orderStepper__step--passed': [
              'delivering',
              'delivered',
              'withdrawal'
            ].includes(order.status)
          })}
        >
          <div className="orderStepper__picto">
            <svg height="25" width="25">
              <use xlinkHref={`${MyAccountSprite}#prepared`} />
            </svg>
          </div>
          <div className="orderStepper__label">
            {t('orderStepper.status.prepared')}
          </div>
        </li>
        <li
          className={classname('orderStepper__step', {
            'orderStepper__step--active': ['delivering'].includes(order.status),
            'orderStepper__step--passed': ['delivered', 'withdrawal'].includes(
              order.status
            )
          })}
        >
          <div className="orderStepper__picto">
            <svg height="20" width="20">
              <use xlinkHref={`${MyAccountSprite}#check`} />
            </svg>
          </div>
          <div className="orderStepper__label">
            {order.status === 'delivering' &&
              t('orderStepper.status.delivering')}
            {order.status === 'delivered' && t('orderStepper.status.delivered')}
            {order.status === 'withdrawal' &&
              t('orderStepper.status.withdrawal')}
          </div>
        </li>
      </ul>
    </div>
  );
};

export default OrderStepper;

export type Address = {
  id?: number;
  name: string;
  isDefault: boolean;
  lastName: string;
  firstName: string;
  mobilePhone?: string;
  phone?: string;
  streetName: string;
  additionalStreetName?: string;
  zipCode: string;
  city: string;
  country: string;
};

export type DQEAddress = {
  checksum: string;
  streetId: string;
  streetName: string;
  streetNumber: string;
  postalCode: string;
  label?: string;
  latitude?: number;
  longitude?: number;
  city: string;
  country: string;
  validStreetNumber?: boolean;
  streetNumberList: Array<number>;
  locality: string;
  type: 'MUNICIPALITY' | 'STREET';
  ignoreValidation?: boolean;
};

export type BillingAddress = Address & {
  type: 'billing';
  additionalInformation?: string;
};

export type DeliveryAddress = Address & {
  type: 'delivery';
  isApartment: boolean;
  floor?: number;
  elevator: boolean;
  buildingNumber?: string;
  digicode?: string;
  deliverymanInstructions?: string;
};

export type Addresses = {
  delivery: Array<DeliveryAddress>;
  billing: Array<BillingAddress>;
};

export const FloorMapping: { [key: string]: number } = {
  'rez de chaussée': 0,
  '1er étage': 1,
  '2ème étage': 2,
  '3ème étage': 3,
  '4ème étage': 4,
  '5ème étage': 5,
  '6ème étage': 6,
  '7ème étage': 7,
  '8ème étage': 8,
  '9ème étage': 9,
  '10ème étage': 10
};

import React, { useState } from 'react';
import { useIsomorphicLayoutEffect } from 'react-use';
import './style.scss';
import {
  RendererComponents as ContentfulRenderComponents,
  Component
} from '@/lib/contentful';
import usePdv from '@/lib/hooks/usePdv';
import getConfig from 'next/config';

const {
  APP_VERSION: appVersion,
  APP_ENV: appEnv
} = getConfig().publicRuntimeConfig;

export type FooterSecondaryProps = {
  links: Array<Component>;
};

const FooterSecondary = ({ links }: FooterSecondaryProps) => {
  const [secondaryFooterLinks, setSecondaryFooterLinks] = useState(links);
  useIsomorphicLayoutEffect(() => {
    const { isEcommerce } = usePdv();
    if (!isEcommerce) {
      const newLinks = links.filter(
        (link) => link.props.href !== '/stores/tos'
      );
      setSecondaryFooterLinks(newLinks);
    }
  }, []);

  return (
    <div className="footerSecondary">
      <div className="footerSecondary__content">
        <ContentfulRenderComponents components={secondaryFooterLinks} />
      </div>
      {process.env.NODE_ENV !== 'production' && (
        <div className="version-info">
          v{appVersion} | env : {appEnv}{' '}
        </div>
      )}
    </div>
  );
};

export default FooterSecondary;

import React, { useEffect, useState } from 'react';
import './style.scss';
import t from '@/lib/i18n';
import ProductModel from '@/lib/model/product';
import { PromotionType } from '@/lib/model/productTypes';
import productApi from '@/lib/api/product';
import Image from '@/components/atoms/image';
import usePdv from '@/lib/hooks/usePdv';
import { ReactComponent as Spinner } from '@/assets/images/icons/spinner.svg';
import Promotion from '@/components/atoms/promotion';
import QuantityDiscount from '@/components/atoms/quantityDiscount';

export type LotProps = {
  productEan?: string;
  product?: ProductModel | null;
  cartItemQty?: number;
};

const Lot = ({ productEan = '', product = null, cartItemQty }: LotProps) => {
  const [productState, setProductState] = useState<ProductModel | null>(
    product
  );

  useEffect(() => {
    if (product && !productEan) return;
    const { ref } = usePdv();
    (async () => {
      const productTemp = await productApi.findByEan(ref, productEan);
      if (productTemp) setProductState(productTemp);
    })();
  }, [productEan, product]);
  return (
    <div className="lot">
      {!productState ? (
        <Spinner className="lot__loader" />
      ) : (
        productState.lot?.products.map((item) => {
          const quantityPromo = item.promotions.find(
            (promo: any) => promo.type === 'quantityDiscount'
          );
          return (
            <div key={item.id} className="lot__product">
              <Image
                width={50}
                height={50}
                src={item.image}
                alt={item.brand}
                className="lot__product__column"
              />
              <div className="lot__product__info lot__product__column">
                <span className="lot__highlight">{item.brand}</span>{' '}
                {item.description}
              </div>
              {!!item.promotions.length && (
                <div className="product__promotions">
                  {item.promotions.map((promo: PromotionType) => {
                    return (
                      <>
                        <Promotion key={promo.type} data={promo} />
                        {quantityPromo && (
                          <QuantityDiscount
                            id={promo.type}
                            qty={cartItemQty || 0}
                            promotion={quantityPromo}
                          />
                        )}
                      </>
                    );
                  })}
                </div>
              )}
              <div className="lot__product__prices lot__product__column">
                {parseFloat(item.crossPrice) > 0 && (
                  <span>{item.crossPrice}</span>
                )}
                <span className="lot__highlight">
                  {t('cart.info.price', { '%price%': item.price })}
                </span>
                {item.priceBy && <span>{item.priceBy}</span>}
              </div>
              <div className="lot__product__quantity lot__product__column">
                {item.quantity}
              </div>
            </div>
          );
        })
      )}
    </div>
  );
};

export default Lot;

import React from 'react';
import AsyncSelect from 'react-select/async';
import classnames from 'classnames';
import './style.scss';

export type FormSelectProps = {
  id?: string;
  name?: string;
  label?: string;
  placeholder?: string;
  className?: string;
  value?: string;
  onChange?: any;
  loadOptions?: any;
  errorMessage?: string;
  size?: 'small' | 'medium' | 'large';
  isDisabled?: boolean;
  isLoading?: boolean;
  isClearable?: boolean;
  isRtl?: boolean;
  isSearchable?: boolean;
  formatOptionLabel?: any;
};

export type Option = {
  value: string | number;
  label: string;
};

const FormSelectV2 = React.forwardRef(
  (
    {
      id,
      name,
      label,
      placeholder,
      className,
      value,
      onChange,
      loadOptions,
      errorMessage,
      formatOptionLabel,
      size = 'small',
      isDisabled = false,
      isLoading = false,
      isClearable = false,
      isRtl = false,
      isSearchable = false
    }: FormSelectProps,
    ref: any
  ) => {
    return (
      <div
        className={classnames(
          className,
          'formSelectV2',
          `formSelectV2--${size}`
        )}
      >
        {label && (
          <label className="formSelectV2__label" htmlFor={id}>
            {label}
          </label>
        )}
        <AsyncSelect
          id={name}
          name={name}
          className={classnames('formSelectV2', `formSelectV2--${size}`, {
            'formSelectV2--hasError': !!errorMessage
          })}
          formatOptionLabel={formatOptionLabel}
          placeholder={placeholder}
          value={value}
          ref={ref}
          loadOptions={loadOptions}
          onChange={onChange}
          isDisabled={isDisabled}
          isLoading={isLoading}
          isClearable={isClearable}
          isRtl={isRtl}
          isSearchable={isSearchable}
        />
        {errorMessage && (
          <div className="formSelect__errorMessage">{errorMessage}</div>
        )}
      </div>
    );
  }
);

export default FormSelectV2;
